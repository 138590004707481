/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzNhM2EzYSIsIj9lcjwjYzRjNGM0IiwiO2VyPCMyNTI1MjV$LCIlPmBePCM0MGJiNmEiLCI~ZXI8I2M2ZWJkMiIsIjtlcjwjMmFhNDRkfiwid2Fybj5gXjwjZmYwMDAwIiwiP2VyPCNmZmIzYjMiLCI7ZXI8I2ZmMDAwMH4sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjEzfQ==
*/

@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

//@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@500&display=swap');
@font-face {
  font-family: "SimplonMono";
  src: url('/assets/fonts/SimplonMono-Regular.otf') format('opentype'); 
  font-weight: normal;
  font-style: normal;
}
     
$fontConfig: (
  display-4: mat.define-typography-level(112px, 112px, 300, 'Helvetica', -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, 'Helvetica', -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, 'Helvetica', 0.0000em),
  display-1: mat.define-typography-level(34px, 40px, 400, 'Helvetica', 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, 'Helvetica', 0.0000em),
  title: mat.define-typography-level(20px, 32px, 500, 'Helvetica', 0.0075em),
  subheading-2: mat.define-typography-level(16px, 28px, 400, 'Helvetica', 0.0094em),
  subheading-1: mat.define-typography-level(15px, 24px, 500, 'Helvetica', 0.0067em),
  body-2: mat.define-typography-level(14px, 24px, 500, 'Helvetica', 0.0179em),
  body-1: mat.define-typography-level(14px, 20px, 400, 'Helvetica', 0.0179em),
  button: mat.define-typography-level(14px, 14px, 500, 'Helvetica', 0.0893em),
  caption: mat.define-typography-level(12px, 20px, 400, 'Helvetica', 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, 'Helvetica', 1.5px)
);

$fontConfig-goldengoose: (
  display-4: mat.define-typography-level(112px, 112px, 300, 'SimplonMono', -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, 'SimplonMono', -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, 'SimplonMono', 0.0000em),
  display-1: mat.define-typography-level(34px, 40px, 400, 'SimplonMono', 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, 'SimplonMono', 0.0000em),
  title: mat.define-typography-level(20px, 32px, 500, 'SimplonMono', 0.0075em),
  subheading-2: mat.define-typography-level(16px, 28px, 400, 'SimplonMono', 0.0094em),
  subheading-1: mat.define-typography-level(15px, 24px, 500, 'SimplonMono', 0.0067em),
  body-2: mat.define-typography-level(14px, 24px, 500, 'SimplonMono', 0.0179em),
  body-1: mat.define-typography-level(14px, 20px, 400, 'SimplonMono', 0.0179em),
  button: mat.define-typography-level(14px, 14px, 500, 'SimplonMono', 0.0893em),
  caption: mat.define-typography-level(12px, 20px, 400, 'SimplonMono', 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, 'SimplonMono', 1.5px)
);


// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base:              black,
  divider:           $dark-dividers,
  dividers:          $dark-dividers,
  disabled:          $dark-disabled-text,
  disabled-button:   rgba($dark-text, 0.26),
  disabled-text:     $dark-disabled-text,
  elevation:         black,
  secondary-text:    $dark-accent-text,
  hint-text:         $dark-disabled-text,
  accent-text:       $dark-accent-text,
  icon:              $dark-accent-text,
  icons:             $dark-accent-text,
  text:              $dark-primary-text,
  slider-min:        $dark-primary-text,
  slider-off:        rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base:              $light-text,
  divider:           $light-dividers,
  dividers:          $light-dividers,
  disabled:          $light-disabled-text,
  disabled-button:   rgba($light-text, 0.3),
  disabled-text:     $light-disabled-text,
  elevation:         black,
  hint-text:         $light-disabled-text,
  secondary-text:    $light-accent-text,
  accent-text:       $light-accent-text,
  icon:              $light-text,
  icons:             $light-text,
  text:              $light-text,
  slider-min:        $light-text,
  slider-off:        rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background:    #fafafa;
$light-bg-darker-5:   darken($light-background, 5%);
$light-bg-darker-10:  darken($light-background, 10%);
$light-bg-darker-20:  darken($light-background, 20%);
$light-bg-darker-30:  darken($light-background, 30%);
$light-bg-lighter-5:  lighten($light-background, 5%);
$dark-bg-tooltip:     lighten(#2c2c2c, 20%);
$dark-bg-alpha-4:     rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12:    rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background:               $light-background,
  status-bar:               $light-bg-darker-20,
  app-bar:                  $light-bg-darker-5,
  hover:                    $dark-bg-alpha-4,
  card:                     $light-bg-lighter-5,
  dialog:                   $light-bg-lighter-5,
  tooltip:                  $dark-bg-tooltip,
  disabled-button:          $dark-bg-alpha-12,
  raised-button:            $light-bg-lighter-5,
  focused-button:           $dark-focused,
  selected-button:          $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle:   $light-bg-darker-10,
  unselected-chip:          $light-bg-darker-10,
  disabled-list-option:     $light-bg-darker-10,
);

// Dark bg
$dark-background:     #2c2c2c;
$dark-bg-lighter-5:   lighten($dark-background, 5%);
$dark-bg-lighter-10:  lighten($dark-background, 10%);
$dark-bg-lighter-20:  lighten($dark-background, 20%);
$dark-bg-lighter-30:  lighten($dark-background, 30%);
$light-bg-alpha-4:    rgba(#fafafa, 0.04);
$light-bg-alpha-12:   rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background:               $dark-background,
  status-bar:               $dark-bg-lighter-20,
  app-bar:                  $dark-bg-lighter-5,
  hover:                    $light-bg-alpha-4,
  card:                     $dark-bg-lighter-5,
  dialog:                   $dark-bg-lighter-5,
  tooltip:                  $dark-bg-lighter-20,
  disabled-button:          $light-bg-alpha-12,
  raised-button:            $dark-bg-lighter-5,
  focused-button:           $light-focused,
  selected-button:          $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle:   $dark-bg-lighter-10,
  unselected-chip:          $dark-bg-lighter-20,
  disabled-list-option:     $dark-bg-lighter-10,
);

// Compute font config
//@include mat.core($fontConfig);

// Theme Config

$mat-warn: (
  main: #ff0000,
  lighter: #ffb3b3,
  darker: #ff0000,
  200: #ff0000, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);




$primary-color-default: #2a2a2a;
$accent-color-default: #4a4a4a;
$primary-color-blue: #bbbbbb;
$accent-color-blue: #54b5dc;
$accent-color-default-dark: #2a2a2a;//00a099
$primary-color-wospee: #18503a;
$accent-color-wospee: #00a75d;
$primary-color-goldengoose: #000000;
$accent-color-goldengoose: #000000;

$mat-primary-default: (
  main: $primary-color-default,
  lighter: #131111,
  darker: #252525,
  200: #3a3a3a, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-primary-default: mat.define-palette($mat-primary-default, main, lighter, darker);

$mat-accent-default: (
  main: $accent-color-default,
  lighter: #c6ebd2,
  darker: #23833e,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-accent-default: mat.define-palette($mat-accent-default, main, lighter, darker);

$mat-primary-blue: (
  main: $primary-color-blue,
  lighter: #131111,
  darker: #252525,
  200: #3a3a3a, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-primary-blue: mat.define-palette($mat-primary-blue, main, lighter, darker);

$mat-accent-blue: (
  main: $accent-color-blue,
  lighter: #c6ebd2,
  darker: #23833e,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-accent-blue: mat.define-palette($mat-accent-blue, main, lighter, darker);

$mat-accent-default-dark: (
  main: $accent-color-default-dark,
  lighter: #333333,
  darker: #1a1a1a,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-accent-default-dark: mat.define-palette($mat-accent-default-dark, main, lighter, darker);


$mat-primary-wospee: (
  main: $primary-color-wospee,
  lighter: #131111,
  darker: #252525,
  200: #3a3a3a, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-primary-wospee: mat.define-palette($mat-primary-wospee, main, lighter, darker);

$mat-accent-wospee: (
  main: $accent-color-wospee,
  lighter: #c6ebd2,
  darker: #23833e,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-accent-wospee: mat.define-palette($mat-accent-wospee, main, lighter, darker);


$mat-primary-goldengoose: (
  main: $primary-color-goldengoose,
  lighter: #101010,
  darker: #000000,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-primary-goldengoose: mat.define-palette($mat-primary-goldengoose, main, lighter, darker);

$mat-accent-goldengoose: (
  main: $accent-color-goldengoose,
  lighter: #000000,
  darker: #000000,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-accent-goldengoose: mat.define-palette($mat-accent-goldengoose, main, lighter, darker);

/////////////////////////////////

$default-theme: mat.define-light-theme((
 color: (
   primary: $theme-primary-default,
   accent: $theme-accent-default,
   warn: $theme-warn
 ),
 typography: $fontConfig,
 density: 0,
));
$blue-theme: mat.define-light-theme((
 color: (
   primary: $theme-primary-blue,
   accent: $theme-accent-blue,
   warn: $theme-warn
 ),
 typography: $fontConfig,
 density: 0,
));
$default-theme-dark: mat.define-dark-theme((
  color: (
    primary: $theme-primary-default,
    accent: $theme-accent-default-dark,
    warn: $theme-warn
  ),
  // Only include `typography` and `density` in the default dark theme.
  typography: $fontConfig,
  density: 0,
));

$wospee-theme: mat.define-light-theme((
 color: (
   primary: $theme-primary-wospee,
   accent: $theme-accent-wospee,
   warn: $theme-warn
 ),
 typography: $fontConfig,
 density: 0,
));

$goldengoose-theme: mat.define-light-theme((
 color: (
   primary: $theme-primary-goldengoose,
   accent: $theme-accent-goldengoose,
   warn: $theme-warn
 ),
 typography: $fontConfig-goldengoose,
 density: 0,
));


@include mat.all-legacy-component-themes($default-theme);
@include mat.all-component-themes($default-theme);
@include mat.legacy-core();
@include mat.core();

// Theme Init
.default-theme {
  .p-component {
    font-family: Arial, Helvetica, sans-serif;
  }

  * {
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .func-menu.sticky {
    background-color: rgba(62, 62, 62, 0.7);
  }

  .title-card {
    background-color: whitesmoke;
    color:black !important;
    font-weight:bold;
    display:flex;
    align-items: center;
    gap:12px;
    border-bottom: 2px solid lightgray;
    padding: 12px !important;
    padding-bottom: 10px !important;
    mat-icon {
      vertical-align: sub;
    }
  }
  .title-card-popup {
    background-color: white !important;
    color:black !important;
    font-weight:bold;
    display:flex;
    align-items: center;
    gap:12px;
    border-bottom: 2px solid lightgray;
    padding: 12px !important;
    padding-bottom: 10px !important;
    mat-icon {
      vertical-align: sub;
    }    
  }
  .title-card-slim { 
    background-color: whitesmoke;
    color:black !important;
    font-weight:bold;
    display:flex;
    align-items: center;
    gap:12px;
    border-bottom: 2px solid lightgray;
    padding: 12px !important;
    padding-bottom: 10px !important;
    mat-icon {
      vertical-align: sub;
    }
    height: 40px;
    font-size: 17px !important;
  }
  mat-card-content {
    padding:16px !important
  }
  .mat-progress-spinner-circle, .mat-spinner circle { 
    stroke: $accent-color-default !important 
  }
  
  .app-button {
    opacity:1;
    transition: background-color .2s ease, opacity .2s ease;
  }
  .app-button:hover {
    //background-color:#4a4a4a; 
    background-color:darken(whitesmoke, 10%); 
    opacity:1;
    transition: background-color .2s ease, opacity .2s ease;
  }

  .module-button-selected {
    background-color:$accent-color-default; 
  }

  .func-button-selected {
    background-color: darken($accent-color-default, 8%);
    color: white !important;
  }

  .func-button:hover, .module-button-selected:hover {
    //background-color:#29a05c;  
    background-color:darken($accent-color-default, 4%) !important; 
    transition: background-color .2s ease;
  }

  .username-button:hover {
    //background-color:#4a4a4a!important;
    background-color:lighten($primary-color-default, 10%); 
    transition: background-color .2s ease;
  }

  .username-item {
    background-color: $primary-color-default !important;
  }
  
  ::ng-deep .mdc-switch .mdc-switch__track {
    background-color: $accent-color-default !important;
  }
}
.blue-theme {  
  @include mat.all-legacy-component-colors($blue-theme);
  .p-component {
    font-family: Arial, Helvetica, sans-serif;
  }

  * {
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .func-menu.sticky {
    background-color: rgba(0, 153, 213, 0.623);
  }

  .title-card {
    background-color: whitesmoke;
    color:black !important;
    font-weight:bold;
    display:flex;
    align-items: center;
    gap:12px;
    border-bottom: 2px solid lightgray;
    padding: 12px !important;
    padding-bottom: 10px !important;
    mat-icon {
      vertical-align: sub;
    }
  }
  .title-card-popup {
    background-color: white !important;
    color:black !important;
    font-weight:bold;
    display:flex;
    align-items: center;
    gap:12px;
    border-bottom: 2px solid lightgray;
    padding: 12px !important;
    padding-bottom: 10px !important;
    mat-icon {
      vertical-align: sub;
    }    
  }
  .title-card-slim { 
    background-color: whitesmoke;
    color:black !important;
    font-weight:bold;
    display:flex;
    align-items: center;
    gap:12px;
    border-bottom: 2px solid lightgray;
    padding: 12px !important;
    padding-bottom: 10px !important;
    mat-icon {
      vertical-align: sub;
    }
    height: 40px;
    font-size: 18px !important;
  }
  mat-card-content {
    padding:16px !important
  }
  .mat-progress-spinner-circle, .mat-spinner circle { 
    stroke: $accent-color-blue !important 
  }
  
  .app-button {
    opacity:1;
    transition: background-color .2s ease, opacity .2s ease;
  }
  .app-button:hover {
    //background-color:#4a4a4a; 
    background-color:darken(whitesmoke, 10%); 
    opacity:1;
    transition: background-color .2s ease, opacity .2s ease;
  }

  .module-button-selected {
    background-color:$accent-color-blue; 
  }

  .func-button-selected {
    background-color: darken($accent-color-blue, 12%);
    color: white !important;
  }

  .func-button:hover, .module-button-selected:hover {
    //background-color:#29a05c;  
    background-color:darken($accent-color-blue, 4%) !important; 
    transition: background-color .2s ease;
  }

  .username-button:hover {
    //background-color:#4a4a4a!important;
    background-color:lighten($primary-color-default, 10%); 
    transition: background-color .2s ease;
  }

  .username-item {
    background-color: $primary-color-default!important;
  }
 
  /*
  .mat-card {
    background: rgba(255,255,255,0.6) !important;
  }
  .mat-card-title {
    background: rgba(255,255,255,0.0) !important;
  }
  .mat-card-content {
    background: rgba(255,255,255,0.0) !important;
  }
  .card-background {
    .mat-card {
      background: rgba(255,255,255,1) !important;
    }
  }
  */
  .drawer-content {
    background: rgba(255,255,255,0);
  }

  .app-logo-out-selected {
    filter: invert(0)!important;
  }
  #nav-menu {
    box-shadow: 0px 1px 4px rgb(184, 184, 184);
  }
  .usernamealt {
    color:black
  }
  .app-button, .username-button, .side-button {
    box-shadow: 0px 3px 4px rgb(184, 184, 184) !important;
  }
  //background: url("../../../../Pictures/wospee_sky.png") !important;  
  //background-size: cover !important;
  //background-color: rgba(255,255,255,0.82)!important;
  //background-blend-mode: lighten!important; 
}

.default-theme-dark {
  @include mat.all-legacy-component-colors($default-theme-dark);
  mat-drawer-content {
    background-color: #525252;
  }
  .mat-drawer-page, .side-button {
    background-color: #909090 !important;
  }

  .loader-div-in, .loader-div-out {
    background-color: #525252ad !important;
  }

  .title-datepicker {
    background-color: $primary-color-default !important;
  }

  .mat-menu-item {
    color:black;
    .mat-icon {
      color:black;
    }
  }

  .inner-card, mat-card-content, .mat-card-content {
    background-color: #646464 !important;
    color:white !important;
  }

  .inner-card-light {
    background-color: #525252 !important;
    color:white !important;
  }

  .p-component {
    font-family: Arial, Helvetica, sans-serif;
  }

  * {
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .func-menu.sticky {
    background-color: rgba(32, 32, 32, 0.7);
  }

  .title-card {
    background-color: $primary-color-default !important;
  }
  .mat-progress-spinner-circle, .mat-spinner circle { 
    stroke: whitesmoke !important 
  }
  .loader-div-in, .loader-div-in-instant, .loader-div-in-logout {
    background-color:#52525285 !important; 
  }
  
  .app-button {
    opacity:1;
    transition: background-color .2s ease, opacity .2s ease;
  }
  .app-button:hover {
    //background-color:#4a4a4a; 
    background-color:darken(whitesmoke, 10%); 
    opacity:1;
    transition: background-color .2s ease, opacity .2s ease;
  }

  .module-button-selected {
    background-color:$primary-color-default; 
  }

  .func-button-selected {
    background-color: lighten($primary-color-default, 10%);
    color: white !important;
  }

  .func-button:hover, .module-button-selected:hover {
    //background-color:#29a05c;  
    background-color:darken($primary-color-default, 5%) !important; 
    transition: background-color .2s ease;
  }

  .username-button:hover {
    //background-color:#4a4a4a!important;
    background-color:lighten($primary-color-default, 10%); 
    transition: background-color .2s ease;
  }

  .username-item {
    background-color: $primary-color-default!important;
  }

  .p-datatable-header {
      background-color: #525252;
      color:white;
      border: #525252 1px solid;
  }

  .column-header, .empty-th, .p-selectable-row  {
      background-color: #525252;
      color:white;
  }

  .p-paginator, .p-paginator-page {
      background-color: #525252;
      color:white;
  }

  .p-sortable-column-icon, .p-column-filter-menu-button.p-filter-icon {
    color:white
  }

  .p-column-filter-menu-button.p-filter-icon {
    color:white
  }

  .p-paginator-current, .p-paginator-first, .p-paginator-prev, .p-paginator-next, .p-paginator-last {
    color: darkgray
  }

  .p-input-icon-right input, .p-dropdown {
    background-color: lightgray;
  }

  .mat-option-text {
    color:white
  }

  .currentapp-icon {
    filter: contrast(0) brightness(.75) grayscale(1) !important;
  }

  .p-component-overlay {
    background-color:#52525285;
  }
}

.wospee-theme {
  @include mat.all-legacy-component-colors($wospee-theme);
  @include mat.all-component-colors($wospee-theme);

  .p-component {
    font-family: Arial, Helvetica, sans-serif;
  }

  * {
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .func-menu {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4) !important;
    color: black;
  }
  .func-menu.sticky {
    background-color: rgba(10, 160, 76, .7);
  }
  .func-button {
    color: white !important;
  }
  .func-button-selected {
    background-color: darken($accent-color-wospee, 8%);
    color: white!important;
  }
  .func-button:hover {
    //background-color:#29a05c;  
    transition: background-color .2s ease;
  }

  .title-card {
    background-color: $accent-color-wospee !important;
  }
  .mat-progress-spinner-circle, .mat-spinner circle { 
    stroke: $accent-color-wospee !important 
  }
  
  .app-button {
    opacity:1;
    transition: background-color .2s ease, opacity .2s ease;
  }
  .selected-app-button {
    background-color: transparent;
    color:white;
    
    .app-logo-out, .app-logo-in  {
      max-height: 48px !important;
    }
  }
  .app-button:hover {
    //background-color:#4a4a4a; 
    background-color:darken(whitesmoke, 10%);
    opacity:1;
    transition: background-color .2s ease, opacity .2s ease;
  }

  .module-button-selected {
    background-color:$accent-color-wospee; 
  }
  .module-button-selected:hover {
    background-color:$accent-color-wospee !important; 
  }

  .username-item {
    background-color: $primary-color-wospee!important;
  }

  .mdc-switch .mdc-switch__track {
    background-color: $accent-color-wospee !important;
  }
}


.goldengoose-theme {
  @include mat.all-legacy-component-colors($goldengoose-theme);
  @include mat.all-component-colors($goldengoose-theme);

  .p-component {
    font-family:"SimplonMono";
    //font-family: Arial, Helvetica, sans-serif;
  }

  * {
    //font-family: Arial, Helvetica, sans-serif;
    font-family:"SimplonMono";
    font-weight:400;

    .message-div-innerhtml {
      font-family: Calibri!important;
      font-weight:unset!important;
      .message-div-innerhtml, * {
        font-family: Calibri!important;
        font-weight:unset!important;
      }
    }

    .angular-editor-textarea {
      font-family: Calibri!important;
      font-weight:unset!important;
      .angular-editor-textarea, * {
        font-family: Calibri!important;
        font-weight:unset!important;
      }
      .angular-editor-textarea, b {
        font-weight: bold!important;
      }
    }
    
  }  

  .modulename-span {
    margin-top: 2px;
  }

  .func-menu.sticky, .back-button-square {
    background-color: rgba(0,0,0,.7)!important;
  }

  .p-datatable-thead {
    text-transform: uppercase;
  }
  
  .title-card {
    background-color: $accent-color-goldengoose !important;
    //font-family:"Cormorant Garamond";
    font-family: "SimplonMono";
    font-size:20px!important;
    //text-transform: uppercase;
  }
  .powerconversation-title {
    font-family: "SimplonMono";
    font-size:20px!important;
    //text-transform: uppercase;
  }
  /*
  .func-button .mat-button-wrapper {
    font-family:"SimplonMono";
    font-weight:400!important;
  }*/

  .mat-progress-spinner-circle, .mat-spinner circle { 
    stroke: $accent-color-goldengoose !important 
  } 
  
  .app-button {
    opacity:1;
    transition: background-color .2s ease, opacity .2s ease;
  }
  .app-button:hover {
    //background-color:#1a1a1a; 
    background-color:darken(white, 15%); 
    opacity:1;  
    transition: background-color .2s ease, opacity .2s ease;
  }

  .module-button-selected {
    background-color:$accent-color-goldengoose; 
  }

  .module-button-selected:hover {     
    background-color:lighten($accent-color-goldengoose, 5%) !important; 
    transition: background-color .2s ease;
  }
  
  .func-button-selected {
    background-color: lighten($accent-color-goldengoose, 25%);
    color: white!important;
  }

  .parent-func-button {
    background-color: lighten($accent-color-goldengoose, 17%);
    color: white!important;
  }
  .parent-func-button:hover {
    background-color: lighten($accent-color-goldengoose, 25%)!important;
    color: white!important;
  }

  .username-button:hover, .func-button:hover {
    //background-color:#1a1a1a!important; 
    background-color:lighten($primary-color-goldengoose, 15%); 
    transition: background-color .2s ease;
  }

  .mat-slider.mat-slider-disabled .mat-slider-track-fill {
    background-color: $accent-color-goldengoose!important;
  }  

  .username-item {
    background-color: $accent-color-goldengoose!important;
  }

}


