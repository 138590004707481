@import 'bootstrap/scss/bootstrap';
@import '@aws-amplify/ui-angular/theme.css';


html, body {
  //height: auto;
  box-sizing: border-box;
}
body { 
  margin: 0; 
}
// font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; 

.main-container{
  width:90%;
  margin: 0 auto;
}
.mat-drawer-backdrop.mat-drawer-shown {
  background-color: transparent;
}
.mat-drawer-container {
  background-color: transparent;
  position: absolute!important;
  z-index:1;
  width: 100%;
}
  
/* ----- TYPOGRAPHY -------- */
.text-align-left{
  text-align: left;
  margin-right: 30px;
}
.text-align-right{
  text-align: right;
  margin-left: 30px;
}
.text-right{
  text-align: right;
}
.text-left{
  text-align: left;
}

/* ------- ANGULAR MATERIAL MOD STYLES ------- */
/* NAVBAR */
.mat-menu-panel.dropdown-usermenu{
  position: absolute;
  top: 14px;
  right: 5px;
}
.mat-menu-panel.dropdown-app{
  position: relative;
  top: 14px;
}
.modulo-item > .mat-list-item-content{
  padding: 0 40px 0 20px!important;
}
.mat-drawer-container.mat-sidenav-container {
  position: absolute!important;
	width: 0!important;
	background-color: transparent!important;
}
.mat-drawer-container.mat-sidenav-container.mat-drawer-container-has-open{
  width: 50%;
}
.mat-drawer-backdrop.mat-drawer-shown {
	background-color: transparent!important;
}
// .mat-drawer-content{
//   margin-left: 80px!important;
// }
.mat-card{
  padding: 0!important;
  border-radius: 8px!important;
  box-shadow: 0 5px 10px  rgb(153 153 153 / 35%);
}
.mat-card-title {
  //height: 50px;
  border-radius: 8px 8px 0 0;
  color: white;  
  line-height: 30px; 
  font-size: 21px; 
  text-align: center; 
  margin-bottom:0; 
}
.mat-card-content {
  background-color: whitesmoke;
  padding: 20px 20px;
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';  
  .mat-badge-content {
    font-family: 'Roboto';
  }
}
.title-card { 
  padding: 8px!important; 
  font-weight: 400; 
  line-height: 30px; 
  font-size: 21px!important; 
  text-align: center; 
  margin-bottom:0!important; 
}
.title-card-slim { 
  padding: 8px!important; 
  font-weight: 400; 
  line-height: 30px; 
  font-size: 21px!important; 
  text-align: center; 
  margin-bottom:0!important; 
}

.button-icon {
  margin-left:-7px;
  margin-top:-2px;
}

.p-datatable-scrollable-table>.p-datatable-thead {
  z-index: 1 !important;
}

p-columnFilter, p-sortIcon {
  transform:scale(.75);
  .p-sortable-column-icon.pi.pi-fw.pi-sort-alt {
    //display: none;
    margin-left: -0.5rem;
  }
  .p-sortable-column-icon.pi.pi-fw.pi-sort-amount-up-alt, .p-sortable-column-icon.pi.pi-fw.pi-sort-amount-down {
    margin-left: -0.5rem;
  }
  .p-sortable-column-badge {
    display: none !important;
  }
}


.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';  
  .mat-badge-content {
    font-family: 'Roboto';
  }
}

.p-datatable{
  border: 1px solid rgb(153 153 153 / 35%);
  border-radius: 5px;
}

.success-snackbar {
  --mdc-snackbar-container-color: #00a75d !important;  
  --mdc-snackbar-supporting-text-color: #ffffff;
  //background-color: #40bb6a!important;
  font-size: 24px;
  display: flex!important;
  white-space: pre-wrap;
}
.error-snackbar {
  //background-color: #f04646!important;
  --mdc-snackbar-container-color: #ea5153!important;
  --mdc-snackbar-supporting-text-color: white!important;
  font-size: 24px;
  display: flex!important;
  white-space: pre-wrap;
}
.warning-snackbar {
  //background-color: #f7e22a!important;
  --mdc-snackbar-container-color: #eef52b!important;
  --mdc-snackbar-supporting-text-color: black!important;
  font-size: 24px;
  display: flex!important;  
  white-space: pre-wrap;
}
.center-snackbar {
  text-align: center;
  margin:auto;  
  justify-content: center;
}

snack-bar-container {
  //backdrop-filter: blur(10px);
  opacity:1;
}


.mat-drawer-side {
  border-right:0 !important;
  box-shadow: 0 0 4px 0 rgba(0,0,0,.4)!important;
}

.mat-drawer-page {
  background-color: whitesmoke !important;
} 

.module-button:hover {    
  background-color:darken(whitesmoke, 10%)!important; 
  transition: background-color .2s ease;
}


.mat-slider-track-fill,
.mat-slider-wrapper,
.mat-slider-track-wrapper,
.mat-slider-track-background {
    height: 4px !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #cccccc;  
  border-radius: 4px;  
} 

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;  
  border-radius: 4px;  
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 600px) {
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {}
// Large devices (desktops, 992px and up)
@media (min-width: 992px) {}
// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}
// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {}

@media print {

  .mat-drawer-content, mat-drawer-container, .mat-drawer-container{
    height: var(--print-height)!important;
  }

  mat-drawer, .mat-drawer, .mat-drawer-page, .mat-drawer-inner-container {
    display:none!important;
  }

  #content {
    height: auto;
    display: fixed;
    width: 100%;
    z-index: 1;
  }

  #plugin {
    display: block;
    height: auto;
    position: absolute;
    width: 100%;
  }

  #sizer {
    position: absolute;
    z-index: 0;
  }

  viewer-page-indicator {
    opacity: 0;
    visibility: hidden;
    z-index: 2;
  }
  p-table {
    .p-paginator {
      display: none!important;
    }
  }
  .mat-tooltip {
    display: none;
  }
  #nav-menu, #magic-banner, #drawer, #lateral-menu-pdf, #function-nav, #hideIcons, #hideSearch, #hideButton {
    display: none !important;
  }
  .buttonActionGoldenGoose, .buttonActionGoldenGoose:hover, .buttonActionGoldenGoose:focus, .buttonActionGoldenGoose:active, .buttonActionWospee, .buttonActionWospee:hover, .buttonActionWospee:focus, .buttonActionWospee:active, .ui-paginator {
    box-shadow: none;
  }
  .mat-drawer-content{
    margin-left: 0!important;
  }

  component-cp017, component-cp018, component-cp019, component-cp020, component-cp021, component-cp022, component-cp023, component-cp024 {
    display:none;
  }

  app-component {    
    page-break-inside: avoid;
  }

  ::-webkit-scrollbar {
    display:none;
  }

  .goldengoose-theme mat-card mat-card-title, .goldengoose-theme .title-card {
    background-color: lightgray !important;
    color:black  !important;
  }
  
  .goldengoose-theme mat-card mat-card-content {
    background-color: rgb(252, 252, 252) !important;
  }

  .title-div {
    background-color: lightgray !important;
    color:black  !important;
  }

  .close-button, .comment-button, .delete-button {
    display:none!important;
  }

}

